import { default as indexi2Z9X0q5w1Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/aviso-legal/index.vue?macro=true";
import { default as indexu5bgY1xGZMMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/cita-previa-taller/index.vue?macro=true";
import { default as _91detail_93OpWUraay6bMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/[detail].vue?macro=true";
import { default as indexV3lGA7gwbmMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/index.vue?macro=true";
import { default as _91detail_93uaiL3tgjboMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/[detail].vue?macro=true";
import { default as indexxfi5s0pX14Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/index.vue?macro=true";
import { default as _91detail_93jSdjI0YSJ7Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-ocasion/[detail].vue?macro=true";
import { default as indexvDpJBE8wmjMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-ocasion/index.vue?macro=true";
import { default as indexVUpscegrnTMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/empresas/index.vue?macro=true";
import { default as indexkJtHm00XDqMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/favoritos/index.vue?macro=true";
import { default as indexLH4bFOHC7gMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gama/[detalle]/index.vue?macro=true";
import { default as indexiR4EgYJtKfMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gama/index.vue?macro=true";
import { default as index65YUKXMpIWMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gracias/index.vue?macro=true";
import { default as indexzB0oeyWFXCMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/index.vue?macro=true";
import { default as indexEkCgLL3gwhMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/instalaciones/index.vue?macro=true";
import { default as _91detail_934yrfxVD5LrMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-nuevas/[detail].vue?macro=true";
import { default as indexzBMx09rDoAMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-nuevas/index.vue?macro=true";
import { default as _91detail_93uUqoYmCuTiMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-ocasion/[detail].vue?macro=true";
import { default as index5jKfIb8t5ZMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-ocasion/index.vue?macro=true";
import { default as _91id_93oNfdXt4GuKMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/noticias/[id].vue?macro=true";
import { default as indexFdbodkPioGMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/noticias/index.vue?macro=true";
import { default as indexWYk9ctmVMGMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/busquedas/index.vue?macro=true";
import { default as indexJ9WDSl6TDmMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/comparador/index.vue?macro=true";
import { default as index6QfIXXVcGIMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/favoritos/index.vue?macro=true";
import { default as indexCjdQWsUbe9Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/index.vue?macro=true";
import { default as indexScdi98RFcqMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/perfil/index.vue?macro=true";
import { default as indexSGAum6D3lFMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/reservas/index.vue?macro=true";
import { default as index6Q3mm4TSdUMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/politica-de-cookies/index.vue?macro=true";
import { default as index2xO3SW19PyMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/politica-de-privacidad/index.vue?macro=true";
import { default as _91id_93nRRKuXP0LLMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-marcas/[id].vue?macro=true";
import { default as indexPHTheGbrwkMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-marcas/index.vue?macro=true";
import { default as _91id_93RuDwsJnio2Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-posventa/[id].vue?macro=true";
import { default as indexS2kI3tnSPKMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-posventa/index.vue?macro=true";
import { default as _91slug_93VP7Qj5Y3UuMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/[type]/[slug].vue?macro=true";
import { default as indexYwVz0Zr8y7Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/error/index.vue?macro=true";
import { default as _91key2_93M7TkrlBRrCMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/estado/[key1]/[key2].vue?macro=true";
import { default as _91key2_93Md6fC8plh6Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/estado/imprimir/[key1]/[key2].vue?macro=true";
import { default as indexxenc5QbZ4KMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/seguros/index.vue?macro=true";
import { default as indexzaejJTT62aMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/sostenibilidad/index.vue?macro=true";
import { default as _91marca_93RfIZBdC8OOMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca].vue?macro=true";
import { default as index8zXHCGGm9yMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/alianzas/index.vue?macro=true";
import { default as indexlgu8ARPAeVMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/aviso-legal/index.vue?macro=true";
import { default as indexqN3Ic6fyv0Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/canal-denuncias/index.vue?macro=true";
import { default as index5LWjbYkhhbMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/centros-chapa-y-pintura/[id]/index.vue?macro=true";
import { default as indexKPjVhKtnoeMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/centros-chapa-y-pintura/index.vue?macro=true";
import { default as indexj3iegoo2CeMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/cita-previa-taller/index.vue?macro=true";
import { default as _91detail_93h2f23fjyftMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-electricos/[detail].vue?macro=true";
import { default as indexEBZn1hq3cKMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-electricos/index.vue?macro=true";
import { default as _91detail_93osY2KGQJ1LMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/[detail].vue?macro=true";
import { default as indexT8eQcvudPjMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/index.vue?macro=true";
import { default as _91detail_93uZYuzfj8Z4Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/kilometro-cero/[detail].vue?macro=true";
import { default as indexUZX6jOa07FMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/kilometro-cero/index.vue?macro=true";
import { default as indexhnPfdYJk2WMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/gracias/index.vue?macro=true";
import { default as indexrwdgDrx1XqMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/historia/index.vue?macro=true";
import { default as indexuDyDIwIOdsMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/index.vue?macro=true";
import { default as indexfk4Ufai02SMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/instalaciones/[id]/index.vue?macro=true";
import { default as indexSucLzftQnSMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/instalaciones/index.vue?macro=true";
import { default as indexzBB6kLDtgIMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/movilidad/index.vue?macro=true";
import { default as _91id_93oI5jPAXNzFMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias-electricos/[id].vue?macro=true";
import { default as indexFDBV5fnKECMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias-electricos/index.vue?macro=true";
import { default as _91id_933HOvAGiuwHMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias/[id].vue?macro=true";
import { default as index38uujCje0kMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias/index.vue?macro=true";
import { default as index7WUT1buSxXMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/busquedas/index.vue?macro=true";
import { default as indexMtd9uyA36TMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/comparador/index.vue?macro=true";
import { default as indexqBQ2mrf3n6Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/favoritos/index.vue?macro=true";
import { default as indexVdC9LVrk6cMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/index.vue?macro=true";
import { default as indexjBm0I4PS8kMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/perfil/index.vue?macro=true";
import { default as indexirmL9wH0iaMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/reservas/index.vue?macro=true";
import { default as indexlDmG3ze2CZMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/politica-de-cookies/index.vue?macro=true";
import { default as index9zjFdXx54VMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/politica-de-privacidad/index.vue?macro=true";
import { default as indexYHgHcsMVctMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/promociones-marcas/index.vue?macro=true";
import { default as index15r2Ika3RYMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/promociones-posventa/index.vue?macro=true";
import { default as _91slug_93PqlVX6uiMMMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/[type]/[slug].vue?macro=true";
import { default as index790FvDQm6fMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/error/index.vue?macro=true";
import { default as _91key2_93Kw3Lu7MVClMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/estado/[key1]/[key2].vue?macro=true";
import { default as _91key2_93nkLAr9bpiDMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/estado/imprimir/[key1]/[key2].vue?macro=true";
import { default as indexqV7DrZ6FFRMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/seguros/index.vue?macro=true";
import { default as indexnQ1XBs4TgqMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/servicios-empresas/index.vue?macro=true";
import { default as indexLK5RYJtCImMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/sostenibilidad/index.vue?macro=true";
import { default as _91id_93xBrdpLjNJkMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/trabaja-con-nosotros/[id].vue?macro=true";
import { default as indexeJo528wRUxMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/trabaja-con-nosotros/index.vue?macro=true";
import { default as index39ue4l2ti2Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/user/recovery-password/[token1]/[token2]/index.vue?macro=true";
export default [
  {
    name: _91marca_93RfIZBdC8OOMeta?.name ?? undefined,
    path: _91marca_93RfIZBdC8OOMeta?.path ?? "/:marca()",
    meta: _91marca_93RfIZBdC8OOMeta || {},
    alias: _91marca_93RfIZBdC8OOMeta?.alias || [],
    redirect: _91marca_93RfIZBdC8OOMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca].vue").then(m => m.default || m),
    children: [
  {
    name: indexi2Z9X0q5w1Meta?.name ?? "marca-aviso-legal",
    path: indexi2Z9X0q5w1Meta?.path ?? "aviso-legal",
    meta: indexi2Z9X0q5w1Meta || {},
    alias: indexi2Z9X0q5w1Meta?.alias || [],
    redirect: indexi2Z9X0q5w1Meta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/aviso-legal/index.vue").then(m => m.default || m)
  },
  {
    name: indexu5bgY1xGZMMeta?.name ?? "marca-cita-previa-taller",
    path: indexu5bgY1xGZMMeta?.path ?? "cita-previa-taller",
    meta: indexu5bgY1xGZMMeta || {},
    alias: indexu5bgY1xGZMMeta?.alias || [],
    redirect: indexu5bgY1xGZMMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/cita-previa-taller/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93OpWUraay6bMeta?.name ?? "marca-coches-nuevos-detail",
    path: _91detail_93OpWUraay6bMeta?.path ?? "coches-nuevos/:detail()",
    meta: _91detail_93OpWUraay6bMeta || {},
    alias: _91detail_93OpWUraay6bMeta?.alias || [],
    redirect: _91detail_93OpWUraay6bMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexV3lGA7gwbmMeta?.name ?? "marca-coches-nuevos",
    path: indexV3lGA7gwbmMeta?.path ?? "coches-nuevos",
    meta: indexV3lGA7gwbmMeta || {},
    alias: indexV3lGA7gwbmMeta?.alias || [],
    redirect: indexV3lGA7gwbmMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93uaiL3tgjboMeta?.name ?? "marca-coches-nuevos-kilometro-cero-detail",
    path: _91detail_93uaiL3tgjboMeta?.path ?? "coches-nuevos/kilometro-cero/:detail()",
    meta: _91detail_93uaiL3tgjboMeta || {},
    alias: _91detail_93uaiL3tgjboMeta?.alias || [],
    redirect: _91detail_93uaiL3tgjboMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexxfi5s0pX14Meta?.name ?? "marca-coches-nuevos-kilometro-cero",
    path: indexxfi5s0pX14Meta?.path ?? "coches-nuevos/kilometro-cero",
    meta: indexxfi5s0pX14Meta || {},
    alias: indexxfi5s0pX14Meta?.alias || [],
    redirect: indexxfi5s0pX14Meta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93jSdjI0YSJ7Meta?.name ?? "marca-coches-ocasion-detail",
    path: _91detail_93jSdjI0YSJ7Meta?.path ?? "coches-ocasion/:detail()",
    meta: _91detail_93jSdjI0YSJ7Meta || {},
    alias: _91detail_93jSdjI0YSJ7Meta?.alias || [],
    redirect: _91detail_93jSdjI0YSJ7Meta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-ocasion/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexvDpJBE8wmjMeta?.name ?? "marca-coches-ocasion",
    path: indexvDpJBE8wmjMeta?.path ?? "coches-ocasion",
    meta: indexvDpJBE8wmjMeta || {},
    alias: indexvDpJBE8wmjMeta?.alias || [],
    redirect: indexvDpJBE8wmjMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-ocasion/index.vue").then(m => m.default || m)
  },
  {
    name: indexVUpscegrnTMeta?.name ?? "marca-empresas",
    path: indexVUpscegrnTMeta?.path ?? "empresas",
    meta: indexVUpscegrnTMeta || {},
    alias: indexVUpscegrnTMeta?.alias || [],
    redirect: indexVUpscegrnTMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/empresas/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJtHm00XDqMeta?.name ?? "marca-favoritos",
    path: indexkJtHm00XDqMeta?.path ?? "favoritos",
    meta: indexkJtHm00XDqMeta || {},
    alias: indexkJtHm00XDqMeta?.alias || [],
    redirect: indexkJtHm00XDqMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/favoritos/index.vue").then(m => m.default || m)
  },
  {
    name: indexLH4bFOHC7gMeta?.name ?? "marca-gama-detalle",
    path: indexLH4bFOHC7gMeta?.path ?? "gama/:detalle()",
    meta: indexLH4bFOHC7gMeta || {},
    alias: indexLH4bFOHC7gMeta?.alias || [],
    redirect: indexLH4bFOHC7gMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gama/[detalle]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiR4EgYJtKfMeta?.name ?? "marca-gama",
    path: indexiR4EgYJtKfMeta?.path ?? "gama",
    meta: indexiR4EgYJtKfMeta || {},
    alias: indexiR4EgYJtKfMeta?.alias || [],
    redirect: indexiR4EgYJtKfMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gama/index.vue").then(m => m.default || m)
  },
  {
    name: index65YUKXMpIWMeta?.name ?? "marca-gracias",
    path: index65YUKXMpIWMeta?.path ?? "gracias",
    meta: index65YUKXMpIWMeta || {},
    alias: index65YUKXMpIWMeta?.alias || [],
    redirect: index65YUKXMpIWMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gracias/index.vue").then(m => m.default || m)
  },
  {
    name: indexzB0oeyWFXCMeta?.name ?? "marca",
    path: indexzB0oeyWFXCMeta?.path ?? "",
    meta: indexzB0oeyWFXCMeta || {},
    alias: indexzB0oeyWFXCMeta?.alias || [],
    redirect: indexzB0oeyWFXCMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEkCgLL3gwhMeta?.name ?? "marca-instalaciones",
    path: indexEkCgLL3gwhMeta?.path ?? "instalaciones",
    meta: indexEkCgLL3gwhMeta || {},
    alias: indexEkCgLL3gwhMeta?.alias || [],
    redirect: indexEkCgLL3gwhMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/instalaciones/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_934yrfxVD5LrMeta?.name ?? "marca-motos-nuevas-detail",
    path: _91detail_934yrfxVD5LrMeta?.path ?? "motos-nuevas/:detail()",
    meta: _91detail_934yrfxVD5LrMeta || {},
    alias: _91detail_934yrfxVD5LrMeta?.alias || [],
    redirect: _91detail_934yrfxVD5LrMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-nuevas/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexzBMx09rDoAMeta?.name ?? "marca-motos-nuevas",
    path: indexzBMx09rDoAMeta?.path ?? "motos-nuevas",
    meta: indexzBMx09rDoAMeta || {},
    alias: indexzBMx09rDoAMeta?.alias || [],
    redirect: indexzBMx09rDoAMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-nuevas/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93uUqoYmCuTiMeta?.name ?? "marca-motos-ocasion-detail",
    path: _91detail_93uUqoYmCuTiMeta?.path ?? "motos-ocasion/:detail()",
    meta: _91detail_93uUqoYmCuTiMeta || {},
    alias: _91detail_93uUqoYmCuTiMeta?.alias || [],
    redirect: _91detail_93uUqoYmCuTiMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-ocasion/[detail].vue").then(m => m.default || m)
  },
  {
    name: index5jKfIb8t5ZMeta?.name ?? "marca-motos-ocasion",
    path: index5jKfIb8t5ZMeta?.path ?? "motos-ocasion",
    meta: index5jKfIb8t5ZMeta || {},
    alias: index5jKfIb8t5ZMeta?.alias || [],
    redirect: index5jKfIb8t5ZMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-ocasion/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oNfdXt4GuKMeta?.name ?? "marca-noticias-id",
    path: _91id_93oNfdXt4GuKMeta?.path ?? "noticias/:id()",
    meta: _91id_93oNfdXt4GuKMeta || {},
    alias: _91id_93oNfdXt4GuKMeta?.alias || [],
    redirect: _91id_93oNfdXt4GuKMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/noticias/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFdbodkPioGMeta?.name ?? "marca-noticias",
    path: indexFdbodkPioGMeta?.path ?? "noticias",
    meta: indexFdbodkPioGMeta || {},
    alias: indexFdbodkPioGMeta?.alias || [],
    redirect: indexFdbodkPioGMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/noticias/index.vue").then(m => m.default || m)
  },
  {
    name: indexWYk9ctmVMGMeta?.name ?? "marca-panel-busquedas",
    path: indexWYk9ctmVMGMeta?.path ?? "panel/busquedas",
    meta: indexWYk9ctmVMGMeta || {},
    alias: indexWYk9ctmVMGMeta?.alias || [],
    redirect: indexWYk9ctmVMGMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/busquedas/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ9WDSl6TDmMeta?.name ?? "marca-panel-comparador",
    path: indexJ9WDSl6TDmMeta?.path ?? "panel/comparador",
    meta: indexJ9WDSl6TDmMeta || {},
    alias: indexJ9WDSl6TDmMeta?.alias || [],
    redirect: indexJ9WDSl6TDmMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/comparador/index.vue").then(m => m.default || m)
  },
  {
    name: index6QfIXXVcGIMeta?.name ?? "marca-panel-favoritos",
    path: index6QfIXXVcGIMeta?.path ?? "panel/favoritos",
    meta: index6QfIXXVcGIMeta || {},
    alias: index6QfIXXVcGIMeta?.alias || [],
    redirect: index6QfIXXVcGIMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/favoritos/index.vue").then(m => m.default || m)
  },
  {
    name: indexCjdQWsUbe9Meta?.name ?? "marca-panel",
    path: indexCjdQWsUbe9Meta?.path ?? "panel",
    meta: indexCjdQWsUbe9Meta || {},
    alias: indexCjdQWsUbe9Meta?.alias || [],
    redirect: indexCjdQWsUbe9Meta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/index.vue").then(m => m.default || m)
  },
  {
    name: indexScdi98RFcqMeta?.name ?? "marca-panel-perfil",
    path: indexScdi98RFcqMeta?.path ?? "panel/perfil",
    meta: indexScdi98RFcqMeta || {},
    alias: indexScdi98RFcqMeta?.alias || [],
    redirect: indexScdi98RFcqMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: indexSGAum6D3lFMeta?.name ?? "marca-panel-reservas",
    path: indexSGAum6D3lFMeta?.path ?? "panel/reservas",
    meta: indexSGAum6D3lFMeta || {},
    alias: indexSGAum6D3lFMeta?.alias || [],
    redirect: indexSGAum6D3lFMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/reservas/index.vue").then(m => m.default || m)
  },
  {
    name: index6Q3mm4TSdUMeta?.name ?? "marca-politica-de-cookies",
    path: index6Q3mm4TSdUMeta?.path ?? "politica-de-cookies",
    meta: index6Q3mm4TSdUMeta || {},
    alias: index6Q3mm4TSdUMeta?.alias || [],
    redirect: index6Q3mm4TSdUMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/politica-de-cookies/index.vue").then(m => m.default || m)
  },
  {
    name: index2xO3SW19PyMeta?.name ?? "marca-politica-de-privacidad",
    path: index2xO3SW19PyMeta?.path ?? "politica-de-privacidad",
    meta: index2xO3SW19PyMeta || {},
    alias: index2xO3SW19PyMeta?.alias || [],
    redirect: index2xO3SW19PyMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/politica-de-privacidad/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nRRKuXP0LLMeta?.name ?? "marca-promociones-marcas-id",
    path: _91id_93nRRKuXP0LLMeta?.path ?? "promociones-marcas/:id()",
    meta: _91id_93nRRKuXP0LLMeta || {},
    alias: _91id_93nRRKuXP0LLMeta?.alias || [],
    redirect: _91id_93nRRKuXP0LLMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-marcas/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPHTheGbrwkMeta?.name ?? "marca-promociones-marcas",
    path: indexPHTheGbrwkMeta?.path ?? "promociones-marcas",
    meta: indexPHTheGbrwkMeta || {},
    alias: indexPHTheGbrwkMeta?.alias || [],
    redirect: indexPHTheGbrwkMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-marcas/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RuDwsJnio2Meta?.name ?? "marca-promociones-posventa-id",
    path: _91id_93RuDwsJnio2Meta?.path ?? "promociones-posventa/:id()",
    meta: _91id_93RuDwsJnio2Meta || {},
    alias: _91id_93RuDwsJnio2Meta?.alias || [],
    redirect: _91id_93RuDwsJnio2Meta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-posventa/[id].vue").then(m => m.default || m)
  },
  {
    name: indexS2kI3tnSPKMeta?.name ?? "marca-promociones-posventa",
    path: indexS2kI3tnSPKMeta?.path ?? "promociones-posventa",
    meta: indexS2kI3tnSPKMeta || {},
    alias: indexS2kI3tnSPKMeta?.alias || [],
    redirect: indexS2kI3tnSPKMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-posventa/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VP7Qj5Y3UuMeta?.name ?? "marca-reservar-type-slug",
    path: _91slug_93VP7Qj5Y3UuMeta?.path ?? "reservar/:type()/:slug()",
    meta: _91slug_93VP7Qj5Y3UuMeta || {},
    alias: _91slug_93VP7Qj5Y3UuMeta?.alias || [],
    redirect: _91slug_93VP7Qj5Y3UuMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYwVz0Zr8y7Meta?.name ?? "marca-reservar-error",
    path: indexYwVz0Zr8y7Meta?.path ?? "reservar/error",
    meta: indexYwVz0Zr8y7Meta || {},
    alias: indexYwVz0Zr8y7Meta?.alias || [],
    redirect: indexYwVz0Zr8y7Meta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/error/index.vue").then(m => m.default || m)
  },
  {
    name: _91key2_93M7TkrlBRrCMeta?.name ?? "marca-reservar-estado-key1-key2",
    path: _91key2_93M7TkrlBRrCMeta?.path ?? "reservar/estado/:key1()/:key2()",
    meta: _91key2_93M7TkrlBRrCMeta || {},
    alias: _91key2_93M7TkrlBRrCMeta?.alias || [],
    redirect: _91key2_93M7TkrlBRrCMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/estado/[key1]/[key2].vue").then(m => m.default || m)
  },
  {
    name: _91key2_93Md6fC8plh6Meta?.name ?? "marca-reservar-estado-imprimir-key1-key2",
    path: _91key2_93Md6fC8plh6Meta?.path ?? "reservar/estado/imprimir/:key1()/:key2()",
    meta: _91key2_93Md6fC8plh6Meta || {},
    alias: _91key2_93Md6fC8plh6Meta?.alias || [],
    redirect: _91key2_93Md6fC8plh6Meta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/estado/imprimir/[key1]/[key2].vue").then(m => m.default || m)
  },
  {
    name: indexxenc5QbZ4KMeta?.name ?? "marca-seguros",
    path: indexxenc5QbZ4KMeta?.path ?? "seguros",
    meta: indexxenc5QbZ4KMeta || {},
    alias: indexxenc5QbZ4KMeta?.alias || [],
    redirect: indexxenc5QbZ4KMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/seguros/index.vue").then(m => m.default || m)
  },
  {
    name: indexzaejJTT62aMeta?.name ?? "marca-sostenibilidad",
    path: indexzaejJTT62aMeta?.path ?? "sostenibilidad",
    meta: indexzaejJTT62aMeta || {},
    alias: indexzaejJTT62aMeta?.alias || [],
    redirect: indexzaejJTT62aMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/sostenibilidad/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index8zXHCGGm9yMeta?.name ?? "alianzas",
    path: index8zXHCGGm9yMeta?.path ?? "/alianzas",
    meta: index8zXHCGGm9yMeta || {},
    alias: index8zXHCGGm9yMeta?.alias || [],
    redirect: index8zXHCGGm9yMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/alianzas/index.vue").then(m => m.default || m)
  },
  {
    name: indexlgu8ARPAeVMeta?.name ?? "aviso-legal",
    path: indexlgu8ARPAeVMeta?.path ?? "/aviso-legal",
    meta: indexlgu8ARPAeVMeta || {},
    alias: indexlgu8ARPAeVMeta?.alias || [],
    redirect: indexlgu8ARPAeVMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/aviso-legal/index.vue").then(m => m.default || m)
  },
  {
    name: indexqN3Ic6fyv0Meta?.name ?? "canal-denuncias",
    path: indexqN3Ic6fyv0Meta?.path ?? "/canal-denuncias",
    meta: indexqN3Ic6fyv0Meta || {},
    alias: indexqN3Ic6fyv0Meta?.alias || [],
    redirect: indexqN3Ic6fyv0Meta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/canal-denuncias/index.vue").then(m => m.default || m)
  },
  {
    name: index5LWjbYkhhbMeta?.name ?? "centros-chapa-y-pintura-id",
    path: index5LWjbYkhhbMeta?.path ?? "/centros-chapa-y-pintura/:id()",
    meta: index5LWjbYkhhbMeta || {},
    alias: index5LWjbYkhhbMeta?.alias || [],
    redirect: index5LWjbYkhhbMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/centros-chapa-y-pintura/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKPjVhKtnoeMeta?.name ?? "centros-chapa-y-pintura",
    path: indexKPjVhKtnoeMeta?.path ?? "/centros-chapa-y-pintura",
    meta: indexKPjVhKtnoeMeta || {},
    alias: indexKPjVhKtnoeMeta?.alias || [],
    redirect: indexKPjVhKtnoeMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/centros-chapa-y-pintura/index.vue").then(m => m.default || m)
  },
  {
    name: indexj3iegoo2CeMeta?.name ?? "cita-previa-taller",
    path: indexj3iegoo2CeMeta?.path ?? "/cita-previa-taller",
    meta: indexj3iegoo2CeMeta || {},
    alias: indexj3iegoo2CeMeta?.alias || [],
    redirect: indexj3iegoo2CeMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/cita-previa-taller/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93h2f23fjyftMeta?.name ?? "coches-electricos-detail",
    path: _91detail_93h2f23fjyftMeta?.path ?? "/coches-electricos/:detail()",
    meta: _91detail_93h2f23fjyftMeta || {},
    alias: _91detail_93h2f23fjyftMeta?.alias || [],
    redirect: _91detail_93h2f23fjyftMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-electricos/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexEBZn1hq3cKMeta?.name ?? "coches-electricos",
    path: indexEBZn1hq3cKMeta?.path ?? "/coches-electricos",
    meta: indexEBZn1hq3cKMeta || {},
    alias: indexEBZn1hq3cKMeta?.alias || [],
    redirect: indexEBZn1hq3cKMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-electricos/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93osY2KGQJ1LMeta?.name ?? "coches-nuevos-detail",
    path: _91detail_93osY2KGQJ1LMeta?.path ?? "/coches-nuevos/:detail()",
    meta: _91detail_93osY2KGQJ1LMeta || {},
    alias: _91detail_93osY2KGQJ1LMeta?.alias || [],
    redirect: _91detail_93osY2KGQJ1LMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexT8eQcvudPjMeta?.name ?? "coches-nuevos",
    path: indexT8eQcvudPjMeta?.path ?? "/coches-nuevos",
    meta: indexT8eQcvudPjMeta || {},
    alias: indexT8eQcvudPjMeta?.alias || [],
    redirect: indexT8eQcvudPjMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93uZYuzfj8Z4Meta?.name ?? "coches-nuevos-kilometro-cero-detail",
    path: _91detail_93uZYuzfj8Z4Meta?.path ?? "/coches-nuevos/kilometro-cero/:detail()",
    meta: _91detail_93uZYuzfj8Z4Meta || {},
    alias: _91detail_93uZYuzfj8Z4Meta?.alias || [],
    redirect: _91detail_93uZYuzfj8Z4Meta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/kilometro-cero/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexUZX6jOa07FMeta?.name ?? "coches-nuevos-kilometro-cero",
    path: indexUZX6jOa07FMeta?.path ?? "/coches-nuevos/kilometro-cero",
    meta: indexUZX6jOa07FMeta || {},
    alias: indexUZX6jOa07FMeta?.alias || [],
    redirect: indexUZX6jOa07FMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/kilometro-cero/index.vue").then(m => m.default || m)
  },
  {
    name: indexhnPfdYJk2WMeta?.name ?? "gracias",
    path: indexhnPfdYJk2WMeta?.path ?? "/gracias",
    meta: indexhnPfdYJk2WMeta || {},
    alias: indexhnPfdYJk2WMeta?.alias || [],
    redirect: indexhnPfdYJk2WMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/gracias/index.vue").then(m => m.default || m)
  },
  {
    name: indexrwdgDrx1XqMeta?.name ?? "historia",
    path: indexrwdgDrx1XqMeta?.path ?? "/historia",
    meta: indexrwdgDrx1XqMeta || {},
    alias: indexrwdgDrx1XqMeta?.alias || [],
    redirect: indexrwdgDrx1XqMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/historia/index.vue").then(m => m.default || m)
  },
  {
    name: indexuDyDIwIOdsMeta?.name ?? "index",
    path: indexuDyDIwIOdsMeta?.path ?? "/",
    meta: indexuDyDIwIOdsMeta || {},
    alias: indexuDyDIwIOdsMeta?.alias || [],
    redirect: indexuDyDIwIOdsMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfk4Ufai02SMeta?.name ?? "instalaciones-id",
    path: indexfk4Ufai02SMeta?.path ?? "/instalaciones/:id()",
    meta: indexfk4Ufai02SMeta || {},
    alias: indexfk4Ufai02SMeta?.alias || [],
    redirect: indexfk4Ufai02SMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/instalaciones/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSucLzftQnSMeta?.name ?? "instalaciones",
    path: indexSucLzftQnSMeta?.path ?? "/instalaciones",
    meta: indexSucLzftQnSMeta || {},
    alias: indexSucLzftQnSMeta?.alias || [],
    redirect: indexSucLzftQnSMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/instalaciones/index.vue").then(m => m.default || m)
  },
  {
    name: indexzBB6kLDtgIMeta?.name ?? "movilidad",
    path: indexzBB6kLDtgIMeta?.path ?? "/movilidad",
    meta: indexzBB6kLDtgIMeta || {},
    alias: indexzBB6kLDtgIMeta?.alias || [],
    redirect: indexzBB6kLDtgIMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/movilidad/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oI5jPAXNzFMeta?.name ?? "noticias-electricos-id",
    path: _91id_93oI5jPAXNzFMeta?.path ?? "/noticias-electricos/:id()",
    meta: _91id_93oI5jPAXNzFMeta || {},
    alias: _91id_93oI5jPAXNzFMeta?.alias || [],
    redirect: _91id_93oI5jPAXNzFMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias-electricos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFDBV5fnKECMeta?.name ?? "noticias-electricos",
    path: indexFDBV5fnKECMeta?.path ?? "/noticias-electricos",
    meta: indexFDBV5fnKECMeta || {},
    alias: indexFDBV5fnKECMeta?.alias || [],
    redirect: indexFDBV5fnKECMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias-electricos/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933HOvAGiuwHMeta?.name ?? "noticias-id",
    path: _91id_933HOvAGiuwHMeta?.path ?? "/noticias/:id()",
    meta: _91id_933HOvAGiuwHMeta || {},
    alias: _91id_933HOvAGiuwHMeta?.alias || [],
    redirect: _91id_933HOvAGiuwHMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias/[id].vue").then(m => m.default || m)
  },
  {
    name: index38uujCje0kMeta?.name ?? "noticias",
    path: index38uujCje0kMeta?.path ?? "/noticias",
    meta: index38uujCje0kMeta || {},
    alias: index38uujCje0kMeta?.alias || [],
    redirect: index38uujCje0kMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias/index.vue").then(m => m.default || m)
  },
  {
    name: index7WUT1buSxXMeta?.name ?? "panel-busquedas",
    path: index7WUT1buSxXMeta?.path ?? "/panel/busquedas",
    meta: index7WUT1buSxXMeta || {},
    alias: index7WUT1buSxXMeta?.alias || [],
    redirect: index7WUT1buSxXMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/busquedas/index.vue").then(m => m.default || m)
  },
  {
    name: indexMtd9uyA36TMeta?.name ?? "panel-comparador",
    path: indexMtd9uyA36TMeta?.path ?? "/panel/comparador",
    meta: indexMtd9uyA36TMeta || {},
    alias: indexMtd9uyA36TMeta?.alias || [],
    redirect: indexMtd9uyA36TMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/comparador/index.vue").then(m => m.default || m)
  },
  {
    name: indexqBQ2mrf3n6Meta?.name ?? "panel-favoritos",
    path: indexqBQ2mrf3n6Meta?.path ?? "/panel/favoritos",
    meta: indexqBQ2mrf3n6Meta || {},
    alias: indexqBQ2mrf3n6Meta?.alias || [],
    redirect: indexqBQ2mrf3n6Meta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/favoritos/index.vue").then(m => m.default || m)
  },
  {
    name: indexVdC9LVrk6cMeta?.name ?? "panel",
    path: indexVdC9LVrk6cMeta?.path ?? "/panel",
    meta: indexVdC9LVrk6cMeta || {},
    alias: indexVdC9LVrk6cMeta?.alias || [],
    redirect: indexVdC9LVrk6cMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/index.vue").then(m => m.default || m)
  },
  {
    name: indexjBm0I4PS8kMeta?.name ?? "panel-perfil",
    path: indexjBm0I4PS8kMeta?.path ?? "/panel/perfil",
    meta: indexjBm0I4PS8kMeta || {},
    alias: indexjBm0I4PS8kMeta?.alias || [],
    redirect: indexjBm0I4PS8kMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: indexirmL9wH0iaMeta?.name ?? "panel-reservas",
    path: indexirmL9wH0iaMeta?.path ?? "/panel/reservas",
    meta: indexirmL9wH0iaMeta || {},
    alias: indexirmL9wH0iaMeta?.alias || [],
    redirect: indexirmL9wH0iaMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/reservas/index.vue").then(m => m.default || m)
  },
  {
    name: indexlDmG3ze2CZMeta?.name ?? "politica-de-cookies",
    path: indexlDmG3ze2CZMeta?.path ?? "/politica-de-cookies",
    meta: indexlDmG3ze2CZMeta || {},
    alias: indexlDmG3ze2CZMeta?.alias || [],
    redirect: indexlDmG3ze2CZMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/politica-de-cookies/index.vue").then(m => m.default || m)
  },
  {
    name: index9zjFdXx54VMeta?.name ?? "politica-de-privacidad",
    path: index9zjFdXx54VMeta?.path ?? "/politica-de-privacidad",
    meta: index9zjFdXx54VMeta || {},
    alias: index9zjFdXx54VMeta?.alias || [],
    redirect: index9zjFdXx54VMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/politica-de-privacidad/index.vue").then(m => m.default || m)
  },
  {
    name: indexYHgHcsMVctMeta?.name ?? "promociones-marcas",
    path: indexYHgHcsMVctMeta?.path ?? "/promociones-marcas",
    meta: indexYHgHcsMVctMeta || {},
    alias: indexYHgHcsMVctMeta?.alias || [],
    redirect: indexYHgHcsMVctMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/promociones-marcas/index.vue").then(m => m.default || m)
  },
  {
    name: index15r2Ika3RYMeta?.name ?? "promociones-posventa",
    path: index15r2Ika3RYMeta?.path ?? "/promociones-posventa",
    meta: index15r2Ika3RYMeta || {},
    alias: index15r2Ika3RYMeta?.alias || [],
    redirect: index15r2Ika3RYMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/promociones-posventa/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PqlVX6uiMMMeta?.name ?? "reservar-type-slug",
    path: _91slug_93PqlVX6uiMMMeta?.path ?? "/reservar/:type()/:slug()",
    meta: _91slug_93PqlVX6uiMMMeta || {},
    alias: _91slug_93PqlVX6uiMMMeta?.alias || [],
    redirect: _91slug_93PqlVX6uiMMMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: index790FvDQm6fMeta?.name ?? "reservar-error",
    path: index790FvDQm6fMeta?.path ?? "/reservar/error",
    meta: index790FvDQm6fMeta || {},
    alias: index790FvDQm6fMeta?.alias || [],
    redirect: index790FvDQm6fMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/error/index.vue").then(m => m.default || m)
  },
  {
    name: _91key2_93Kw3Lu7MVClMeta?.name ?? "reservar-estado-key1-key2",
    path: _91key2_93Kw3Lu7MVClMeta?.path ?? "/reservar/estado/:key1()/:key2()",
    meta: _91key2_93Kw3Lu7MVClMeta || {},
    alias: _91key2_93Kw3Lu7MVClMeta?.alias || [],
    redirect: _91key2_93Kw3Lu7MVClMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/estado/[key1]/[key2].vue").then(m => m.default || m)
  },
  {
    name: _91key2_93nkLAr9bpiDMeta?.name ?? "reservar-estado-imprimir-key1-key2",
    path: _91key2_93nkLAr9bpiDMeta?.path ?? "/reservar/estado/imprimir/:key1()/:key2()",
    meta: _91key2_93nkLAr9bpiDMeta || {},
    alias: _91key2_93nkLAr9bpiDMeta?.alias || [],
    redirect: _91key2_93nkLAr9bpiDMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/estado/imprimir/[key1]/[key2].vue").then(m => m.default || m)
  },
  {
    name: indexqV7DrZ6FFRMeta?.name ?? "seguros",
    path: indexqV7DrZ6FFRMeta?.path ?? "/seguros",
    meta: indexqV7DrZ6FFRMeta || {},
    alias: indexqV7DrZ6FFRMeta?.alias || [],
    redirect: indexqV7DrZ6FFRMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/seguros/index.vue").then(m => m.default || m)
  },
  {
    name: indexnQ1XBs4TgqMeta?.name ?? "servicios-empresas",
    path: indexnQ1XBs4TgqMeta?.path ?? "/servicios-empresas",
    meta: indexnQ1XBs4TgqMeta || {},
    alias: indexnQ1XBs4TgqMeta?.alias || [],
    redirect: indexnQ1XBs4TgqMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/servicios-empresas/index.vue").then(m => m.default || m)
  },
  {
    name: indexLK5RYJtCImMeta?.name ?? "sostenibilidad",
    path: indexLK5RYJtCImMeta?.path ?? "/sostenibilidad",
    meta: indexLK5RYJtCImMeta || {},
    alias: indexLK5RYJtCImMeta?.alias || [],
    redirect: indexLK5RYJtCImMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/sostenibilidad/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xBrdpLjNJkMeta?.name ?? "trabaja-con-nosotros-id",
    path: _91id_93xBrdpLjNJkMeta?.path ?? "/trabaja-con-nosotros/:id()",
    meta: _91id_93xBrdpLjNJkMeta || {},
    alias: _91id_93xBrdpLjNJkMeta?.alias || [],
    redirect: _91id_93xBrdpLjNJkMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/trabaja-con-nosotros/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeJo528wRUxMeta?.name ?? "trabaja-con-nosotros",
    path: indexeJo528wRUxMeta?.path ?? "/trabaja-con-nosotros",
    meta: indexeJo528wRUxMeta || {},
    alias: indexeJo528wRUxMeta?.alias || [],
    redirect: indexeJo528wRUxMeta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/trabaja-con-nosotros/index.vue").then(m => m.default || m)
  },
  {
    name: index39ue4l2ti2Meta?.name ?? "user-recovery-password-token1-token2",
    path: index39ue4l2ti2Meta?.path ?? "/user/recovery-password/:token1()/:token2()",
    meta: index39ue4l2ti2Meta || {},
    alias: index39ue4l2ti2Meta?.alias || [],
    redirect: index39ue4l2ti2Meta?.redirect,
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/user/recovery-password/[token1]/[token2]/index.vue").then(m => m.default || m)
  }
]