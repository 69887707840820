import validate from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/middleware/redirect.global.ts";
import manifest_45route_45rule from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  brands: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/middleware/brands.ts"),
  "font-change": () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/middleware/font-change.ts"),
  "sanctum:auth": () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.mjs"),
  "sanctum:guest": () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.mjs")
}